import React from "react";
import withHydrationOnDemand from "react-hydration-on-demand";
import LinkWrapper from "../../global/components/LinkWrapper/LinkWrapper";
import { useLinkContentActions } from "../../shared/hooks/useLinkContentAction";
import StyledButton from "../../shared/styleElements/StyledButton/StyledButton";
import {
  BoxButtonContentScheme,
  BoxButtonContentSettingsScheme,
} from "./BoxButton.scheme";
import { StyledButtonAlignment } from "./BoxButton.styled";
import { IBoxButtonProps } from "./BoxButton.types";
import { Memo } from "@ecp-boxes/helpers/memoWrapper";
import { SelectedGoToOption } from "@ecp-boxes/settingsPatterns/contentPatterns.types";
import useIsActiveSectionButton from "@ecp-boxes/shared/hooks/useIsActiveSectionButton";
import classNames from "classnames";
import useCompareLogic from "./useCompareLogic";

const BoxButton: React.FC<IBoxButtonProps> = (props) => {
  const content = BoxButtonContentScheme.parse(props.content);

  const contentSettings = BoxButtonContentSettingsScheme.parse(
    props.contentSettings
  );

  const { linkContentActions, getHref, addToWishlistButton } =
    useLinkContentActions(
      props.path,
      undefined,
      content.buttonLink.selectedGoToOption ===
        SelectedGoToOption.ADD_TO_WISHLIST
        ? content.buttonLink.addToWishlistSetting
        : undefined
    );

  const { getIsActiveByData } = useIsActiveSectionButton(
    props.path.getFirstSection()
  );

  const { compareButton } = useCompareLogic(
    content.buttonLink.selectedGoToOption === SelectedGoToOption.COMPARISON
      ? content.buttonLink.comparisonSetting
      : undefined
  );

  return (
    <StyledButtonAlignment
      alignment={contentSettings.buttonAlignment}
      className="button-container__alignment button-custom-style"
    >
      <LinkWrapper
        href={getHref(content.buttonLink)}
        openInNewTab={content.buttonLink.goToPageSetting?.openInNewTab}
      >
        <>
          {content.buttonLink.selectedGoToOption ===
            SelectedGoToOption.COMPARISON &&
            compareButton.isSuccessCompares && (
              <StyledButton
                $settings={compareButton.buttonStyle}
                className={classNames("button-container", {
                  "active-button": getIsActiveByData(content.buttonLink),
                })}
                onClick={() => compareButton.onClick()}
                renderAs="button"
              >
                {compareButton.label}
              </StyledButton>
            )}
          {content.buttonLink.selectedGoToOption ===
            SelectedGoToOption.ADD_TO_WISHLIST && (
            <StyledButton
              $settings={addToWishlistButton.buttonStyle}
              className={classNames("button-container", {
                "active-button": getIsActiveByData(content.buttonLink),
              })}
              onClick={() => linkContentActions(content.buttonLink)}
              renderAs="button"
            >
              {addToWishlistButton.label}
            </StyledButton>
          )}

          {content.buttonLink.selectedGoToOption !==
            SelectedGoToOption.COMPARISON &&
            content.buttonLink.selectedGoToOption !==
              SelectedGoToOption.ADD_TO_WISHLIST && (
              <StyledButton
                $settings={contentSettings.buttonStyle}
                className={classNames("button-container", {
                  "active-button": getIsActiveByData(content.buttonLink),
                })}
                onClick={() => linkContentActions(content.buttonLink)}
                renderAs="button"
              >
                {content.buttonLabel}
              </StyledButton>
            )}
        </>
      </LinkWrapper>
    </StyledButtonAlignment>
  );
};

export default Memo(withHydrationOnDemand({ on: ["visible"] })(BoxButton));
