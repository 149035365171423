import { usePageContext } from "@ecp-boxes/helpers/pageContext";
import {
  IBoxButtonMessages,
  IBoxButtonSettings,
} from "@ecp-boxes/settingsPatterns/boxes/BoxButton/StyledButton.types";
import { IComparisonSetting } from "@ecp-boxes/settingsPatterns/contentPatterns.scheme";
import { useOpenPortalSnackbar } from "@ecp-boxes/shared/components/Snackbar/Snackbar";
import { useMessagesSettingsContext } from "@ecp-boxes/structure/Contexts/MessagesSettingsContext";
import { PageCategoryType } from "@ecp-pageTypes";
import * as productCompareApi from "@ecp-redux/api/productCompare";
import {
  IBoxCompareMessages,
  IBoxCompareSettings,
} from "../BoxCompare/BoxCompare.types";
import {
  IBoxProductSliderMessages,
  IBoxProductSliderSettings,
} from "../BoxProductSlider/BoxProductSlider.types";

const useCompareLogic = (comparisonSetting?: IComparisonSetting) => {
  const { messages, settings } = useMessagesSettingsContext<
    IBoxButtonMessages | IBoxCompareMessages | IBoxProductSliderMessages,
    IBoxButtonSettings | IBoxCompareSettings | IBoxProductSliderSettings
  >();
  const { openPortalSnackbar } = useOpenPortalSnackbar();
  const context = usePageContext();

  const { data: productCompare, isSuccess: isSuccessCompare } =
    productCompareApi.useGetProductCompareQuery(undefined, {
      skip: !comparisonSetting,
    });
  const currentSku =
    context.type === PageCategoryType.PRODUCT ? context.productSku : undefined;
  const isCompared = productCompare?.skus.includes(currentSku ?? "");

  const [addToProductCompare] =
    productCompareApi.usePostAddProductCompareMutation();
  const [removeFromProductCompare] =
    productCompareApi.usePutRemoveProductCompareMutation();

  const getCurrentLabel = () => {
    if (!comparisonSetting) return null;
    return comparisonSetting[
      isCompared ? "removeButtonLabel" : "addButtonLabel"
    ];
  };

  const getCurrentButtonStyle = () => {
    if (!comparisonSetting) return null;
    return comparisonSetting[
      isCompared ? "removeButtonStyle" : "addButtonStyle"
    ];
  };

  const handleOnClick = () => {
    if (!currentSku) return null;

    if (isCompared) {
      removeFromProductCompare(currentSku)
        .unwrap()
        .then(() => {
          openPortalSnackbar(
            settings.remove_compare_product_snackbar,
            messages.remove_compare_product_snackbar
          );
        });
    } else {
      addToProductCompare(currentSku)
        .unwrap()
        .then(() => {
          openPortalSnackbar(
            settings.add_compare_product_snackbar,
            messages.add_compare_product_snackbar
          );
        })
        .catch(() => {
          openPortalSnackbar(
            settings.error_message_snackbar,
            messages.error_message
          );
        });
    }
  };

  return {
    compareButton: {
      label: getCurrentLabel(),
      buttonStyle: getCurrentButtonStyle(),
      onClick: handleOnClick,
      isSuccessCompares: isSuccessCompare,
    },
  };
};

export default useCompareLogic;
